//iphoneのUI考慮高さ取得
/*
$(function() {

    $('#loader').css('height', $(window).height() + 'px');

});
*/
//ローディング
$(function() {
    $(window).load(function() {
        $('.all').addClass('done');
        //$('html,body').animate({ scrollTop: 0 }, '1');
        //new WOW({
        //    live: true
        //}).init();
    });
    //
});

//slick scrl
 $(document).ready(function() {
    $('.scrl1').slick({
        autoplay: true,
        autoplaySpeed: 0,
        speed: 5000,
        cssEase: "linear",
        slidesToShow: 4,
        swipe: false,
        arrows: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        responsive: [{
            breakpoint: 750,
            settings: {
                slidesToShow: 2
            }
        }]
    });
});


$(window).scroll(function() {
    wh = $(window).height() * .1;
    scrollY = $(window).scrollTop();

    if (scrollY > wh) {
        $('.all').addClass('scrled');
    } else {
        $('.all').removeClass('scrled');
    }
});


//window幅変わったらリロード
/*
var timerw = 0;
var currentWidth = window.innerWidth;
$(window).resize(function() {
    if (currentWidth == window.innerWidth) {
        return;
    }
    if (timerw > 0) {
        clearTimeout(timerw);
    }
    timerw = setTimeout(function() {
        location.reload();
    }, 200);
});
*/

//easing
jQuery.easing['jswing'] = jQuery.easing['swing'];
jQuery.extend(jQuery.easing, {
    easeInOutExpo: function(x, t, b, c, d) {
        if (t == 0) return b;
        if (t == d) return b + c;
        if ((t /= d / 2) < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
        return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
    }
});


//ページ内スクロール
$(function() {
    $('a[href^="#"]').click(function() {
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top;
        $("html, body").animate({
            scrollTop: position
        }, 1500, "easeInOutExpo");
        return false;
    });
});

//menu

$(function() {
    $('.menu-ico').click(function() {
        $('.all').toggleClass('navon');
        return false;
    });
});



//toggle

$(function() {
    $('.ico-toggle').click(function() {
        $(this).toggleClass('on');
        $(this).next().slideToggle();
        //return false;
    });
});

